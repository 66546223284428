import axios from 'axios';
import { deleteCookie, getCookie } from 'cookies-next';
import { useRouter } from 'next/router';
import useSWR from 'swr';

export type Member = {
  id: number;
  email: string;
  socialId: string;
  username: string;
  role: string;
  name: string;
  phoneNumber: string;
  gender: 'MALE' | 'FEMALE';
  ageRange: '20' | '30' | '40' | '50' | '60';
  location: '서울' | '경기도' | '강원도' | '충청도' | '경상도' | '전라도';
  isOAuth: boolean;
};

type FetcherParams = [string, string];

const fetcher = async <T>([url, accessToken]: FetcherParams) => {
  const { data } = await axios.get<GroundResponse<T>>(url, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return data;
};

export const useMember = () => {
  const accessToken = getCookie('token');
  const swr = useSWR<GroundResponse<Member>>(
    !!accessToken ? [`${process.env.NEXT_PUBLIC_APP_API}/app/users/me`, accessToken] : null,
    fetcher<Member>,
    {}
  );
  return swr;
};

export const logout = async () => {
  await deleteCookie('token');
};

export const isSignedIn = () => {
  const accessToken = getCookie('token');
  return !!accessToken;
};

export const get_kakao_authcode_url = `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.NEXT_PUBLIC_KAKAO_REST_KEY}&redirect_uri=${process.env.NEXT_PUBLIC_APP_URL}/login&response_type=code`;
export const check_registered_url = 'https://dev.api.groundtour.co.kr/app/users/is_registered';

const loginFetcher = async ([url, code]: FetcherParams) => {
  const { data } = await axios.get(
    `${url}?code=${code}&redirect_uri=${process.env.NEXT_PUBLIC_APP_URL}/login`
  );
  return data;
};

export const useCheckRegistered = (code: string | undefined) => {
  const router = useRouter();

  const swr = useSWR<GroundResponse<boolean>>(
    code ? [check_registered_url, code] : null,
    loginFetcher,
    {
      revalidateOnFocus: false,
      shouldRetryOnError: false,
      onSuccess(data, key, config) {
        const is_registered = data.result;
        if (is_registered) {
          router.replace(
            `https://dev.api.groundtour.co.kr/oauth2/authorization/kakao?redirect_uri=${process.env.NEXT_PUBLIC_APP_URL}/login`
          );
        } else {
          router.replace(
            `https://dev.api.groundtour.co.kr/oauth2/authorization/kakao?redirect_uri=${process.env.NEXT_PUBLIC_APP_URL}/signup`
          );
        }
      },
    }
  );
  return swr;
};
