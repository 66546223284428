import { SearchProduct } from '@/hooks/search/Search.hooks';
import Link from 'next/link';
import styles from '../../../styles/main/recent-view-item-list.module.scss';

type Props = {
  recentItems: SearchProduct[];
};

export default function RecentViewItemList({ recentItems }: Props) {
  return (
    <div className={styles.root}>
      <div className={styles.title}>최근 본 상품</div>
      <div className={styles.itemSlideContainer}>
        {recentItems.map((item) => (
          <Link
            key={item.id}
            href={{
              pathname: `/product/${item.id}`,
              query: {
                categoryId: item.categoryId,
                villageName: item.villageName,
              },
            }}>
            <div className={styles.itemCard}>
              <img className={styles.itemImg} src={item.thumbnailUrl} />
              <div className={styles.itemContent}>
                <div className={styles.itemSubtitle}>
                  {item.categoryName} • {item.location}
                </div>
                <p className={styles.itemMainTitle}>{item.name}</p>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}
