import axios from 'axios';
import { getCookie } from 'cookies-next';
import useSWR from 'swr';

export type NotificationInfo = {
  id: number;
  reservationId: number;
  reservationNumber: string;
  productName: string;
  productOptionName: string;
  isRead: boolean;
  type: string;
  createdAt: string;
  isReviewed: boolean;
};

export type NofificationListResponse = {
  content: NotificationInfo[];
};

type FetcherParams = [string, string];

const fetcher = async <T>([url, accessToken]: FetcherParams) => {
  const { data } = await axios.get<GroundResponse<T>>(url, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return data;
};

export const useUnreadNotification = () => {
  const accessToken = getCookie('token');
  const swr = useSWR<GroundResponse<boolean>>(
    !!accessToken
      ? [`${process.env.NEXT_PUBLIC_APP_API}/app/notifications/unread`, accessToken]
      : null,
    fetcher<boolean>,
    {}
  );
  return swr;
};

export const useNotificationList = () => {
  const accessToken = getCookie('token');
  const swr = useSWR<GroundResponse<NofificationListResponse>>(
    !!accessToken ? [`${process.env.NEXT_PUBLIC_APP_API}/app/notifications`, accessToken] : null,
    fetcher<NofificationListResponse>,
    {}
  );
  return swr;
};

export const readNotifications = (ids: number[]) => {
  const accessToken = getCookie('token');
  axios
    .patch(
      `${process.env.NEXT_PUBLIC_APP_API}/app/notifications`,
      { ids },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
    .catch((error) => {
      console.error('알림 읽음 처리 실패:', error);
    });
};
