import axios from 'axios';
import { getCookie } from 'cookies-next';
import useSWR from 'swr';
import { SearchProduct } from '../search/Search.hooks';

type FetcherParams = [string, string];

const fetcher = async <T>([url, accessToken]: FetcherParams) => {
  const { data } = await axios.get<GroundResponse<T>>(url, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json;charset=UTF-8',
    },
    data: {},
  });
  return data;
};

export type RecentProductListResponse = {
  content: SearchProduct[];
};

export const useRecentProductList = () => {
  const accessToken = getCookie('token');
  const swr = useSWR<GroundResponse<RecentProductListResponse>>(
    !!accessToken
      ? [`${process.env.NEXT_PUBLIC_APP_API}/app/recent-view-products`, accessToken]
      : null,
    fetcher<RecentProductListResponse>,
    {}
  );
  return swr;
};
