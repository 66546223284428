import axios from 'axios';
import useSWR from 'swr';
import { ProductListItem } from './Main.hooks';

const fetcher = async (url: string) => {
  const { data } = await axios.get<GroundResponse<ProductListItem[]>>(url);
  return data;
};

export const useLocationTopProducts = (location: string) => {
  const swr = useSWR<GroundResponse<ProductListItem[]>>(
    `${process.env.NEXT_PUBLIC_APP_API}/app/products/v2/location/top-10?location=${location}`,
    fetcher
  );
  return swr;
};
