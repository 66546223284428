import { Container } from '@/components/container/Container';
import FooterComponent from '@/components/layout/Footer';
import classNames from 'classnames';
import { HTMLAttributes, ReactNode, RefObject } from 'react';
import styles from '../../../styles/layout/global-layout.module.scss';

export type LayoutProps = {
  className?: string;
  Header?: ReactNode;
  children: ReactNode;
  Footer?: ReactNode;
  onBodyScroll?: HTMLAttributes<HTMLDivElement>['onScroll'];
  bodyRef?: RefObject<HTMLDivElement>;
} & HTMLAttributes<HTMLDivElement>;

export const Layout = ({
  className,
  Header,
  children,
  Footer = <FooterComponent />,
  onBodyScroll,
  bodyRef,
  ...props
}: LayoutProps) => {
  return (
    <div className={classNames(styles.root, className)} {...props}>
      {!!Header && <div className={classNames(styles.header, 'layout-header')}>{Header}</div>}
      <div className={styles.body} onScroll={onBodyScroll} ref={bodyRef}>
        <Container className={styles.content}>{children}</Container>
      </div>
      {!!Footer && <div className={classNames(styles.footer, 'layout-footer')}>{Footer}</div>}
    </div>
  );
};
