import { ProductListItem } from '@/hooks/main/Main.hooks';
import { useLocationTopProducts } from '@/hooks/main/TopLocation.hook';
import { formatCurrency } from '@/utils/number';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import styles from '../../../styles/main/popular-by-region.module.scss';
import FloatingBadgeList from './FloatingBadgeList';

const regions = ['서울', '경기', '인천', '강원', '부산', '대구', '대전'];

type Props = {
  topItems: ProductListItem[];
};

export default function PopularByRegion({ topItems }: Props) {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [productList, setProductList] = useState<ProductListItem[]>(topItems);

  const location = regions[selectedIndex];
  const { data: { result: topProducts = [] } = {}, isLoading } = useLocationTopProducts(location);

  useEffect(() => {
    if (isLoading) return;
    setProductList([...topProducts]);
  }, [topProducts, isLoading]);

  return (
    <div className={styles.root}>
      <div className={styles.title}>지역별 인기</div>
      <FloatingBadgeList
        list={regions.map((item) => ({ label: item, value: item }))}
        setSelectedIndex={setSelectedIndex}
      />
      <div className={styles.itemSlideContainer}>
        {productList.map((item, index) => (
          <Link
            key={`topLoc-${item.id}`}
            className={styles.itemSlide}
            href={{
              pathname: `/product/${item.id}`,
              query: {
                categoryId: item.categoryId,
                villageName: item.villageName,
              },
            }}>
            <div className={styles.itemImgWrapper}>
              <img src={item.thumbnailUrl} />
              <div className={styles.itemNumber}>{index + 1}</div>
            </div>
            <div className={styles.itemContent}>
              <div className={styles.itemSubTitle}>
                {item.categoryName} • {item.location}
              </div>
              <p className={styles.itemMainTitle}>{item.name}</p>
              <div className={styles.itemPriceWrapper}>
                <div className={styles.currentPrice}>{formatCurrency(item.price)}원</div>
                <div className={styles.originPrice}>{formatCurrency(item.originPrice)}원</div>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}
