import classNames from 'classnames';
import { useState } from 'react';
import styles from '../../../styles/main/floating-badge-list.module.scss';

type Props = {
  list: { label: string; value: string | number }[];
  setSelectedIndex?: (index: number) => void;
};

export default function FloatingBadgeList({ list, setSelectedIndex }: Props) {
  const [activeIndex, setActiveIndex] = useState(0);
  const onItemClick = (index: number) => {
    setActiveIndex(index);
    setSelectedIndex?.(index);
  };

  return (
    <div className={styles.root}>
      {list.map((item, index) => (
        <div
          key={item.value}
          className={classNames(styles.badge, { [styles.active]: activeIndex === index })}
          onClick={() => onItemClick(index)}>
          {item.label}
        </div>
      ))}
    </div>
  );
}
