import { Category } from '@/hooks/main/Main.hooks';
import Link from 'next/link';
import styles from '../../../styles/main/category-list.module.scss';

type Props = {
  categories: Category[];
};

export default function CategoryList({ categories }: Props) {
  return (
    <div className={styles.categoryList}>
      {categories
        .sort((a, b) => a.orderNumber - b.orderNumber)
        .map((category) => (
          <Link key={category.id} href={`/category/${category.id}`}>
            <div className={styles.category}>
              <img src={category.iconUrl} />
              <div>{category.name}</div>
            </div>
          </Link>
        ))}
    </div>
  );
}
